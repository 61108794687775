import axios from "axios";

// let root = "http://localhost:8888";
const root = "https://www.eyedock.com";

const apiClient = axios.create({
  baseURL: root + "/bcse/api",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

const markdownApi = axios.create({
  baseURL: root + "/markdownFiles/getter.php",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default {
  searchAll(q, module = null, type = null, more = null) {
    if (!q || q == "") return null;
    let queryArr = ["q=" + q];
    let queryString = "?";
    if (module) queryArr.push("module=" + module);
    if (type) queryArr.push("type=" + type);
    if (more) queryArr.push(more); // eg more = "groups='TORIC'"
    if (queryArr.length) {
      queryString += queryArr.join("&");
      return apiClient.get("/searchAll2" + queryString);
    }
  },
  summaryForItemId(itemId, module = "CL", type = "PRODUCT") {
    if (!itemId || itemId == "") return null;
    return apiClient.get(
      `/summaryForItemId/${itemId}?module=${module}&type=${type}`
    );
  },

  // ------ cl api calls --------------------------------
  // generic api calls (when you just need to supply a path ..and maybe a param string)
  genericApiCall(path, paramString = "") {
    let p = path + paramString;
    // console.log(p);
    return apiClient.get(p);
  },

  // allCompaniesAPI() {
  //   return apiClient.get("/lens/companies");
  // },
  // allPolymers() {
  //   return apiClient.get("/lens/polymers");
  // },
  initialLensDataAPI(paramString = "") {
    let theRest = "/lens/initialLensData?detailed=1&" + paramString;
    return apiClient.get(theRest);
  },
  // popularLenses() {
  //   return apiClient.get("/lens/popularLenses");
  // },
  lensSearchAPI(paramString = "") {
    let theRest = "/lens/list?detailed=1&" + paramString;
    return apiClient.get(theRest);
  },
  // adapt this to just accept an rxObj
  lensPowerSearchAPI(paramString = "") {
    let theRest = "/searchByRefraction?" + paramString;
    return apiClient.get(theRest);
  },
  bestPowerForLensIdAPI(lensID, paramString) {
    let theRest = "/bestpower?lensID=" + lensID;
    theRest += "&" + paramString;
    return apiClient.get(theRest);
  },
  lensDetailsAPI(lensId = "", privateId = null) {
    let pid = privateId ? "?privateId=" + privateId : "";
    let url = "/lens/" + lensId + pid;
    console.log(url);
    return apiClient.get(url);
  },
  // privateLabels() {
  //   return apiClient.get("/lens/privateLabels");
  // },
  // hybrids() {
  //   return apiClient.get("/lens/hybrids");
  // },

  // ------- prices stuff -----
  // params object must include keys 'lens_id', 'user_id', and 'user_key'
  lensPrice(params) {
    let paramString = new URLSearchParams(params).toString();
    return apiClient.get("/prices/lensPrice?" + paramString);
  },
  // params object must include keys 'user_id' and 'user_key'.
  // Optional "companies": "ALL", "MAIN", or list ("1,2")
  practicePrices(params) {
    let paramString = new URLSearchParams(params).toString();
    return apiClient.get("/prices/practicePrices?" + paramString);
  },
  setLensPrices(data) {
    return apiClient.post("/prices/setClPrice", data);
  },

  // --------------------meds api calls----------------------------
  // medCompanies() {
  //   return apiClient.get("/meds/companies");
  // },
  medIngredients() {
    return apiClient.get("/meds/ingredients");
  },
  popularMeds(category = "") {
    //console.log("ApiServices:popularMeds()" + nuthin);
    category = category ? "/" + category : "";
    return apiClient.get("/meds/popular" + category);
  },
  medMoas() {
    return apiClient.get("/meds/moas");
  },
  medNameSearch(term) {
    return apiClient.get("/meds/search/" + encodeURIComponent(term));
  },
  medAutocomplete(term) {
    // deprecated?
    return apiClient.get("/meds/autocomplete/" + encodeURIComponent(term));
  },
  autocompleteData() {
    return apiClient.get("/meds/autocompleteData");
  },
  medsForIngredient(ingredientId) {
    return apiClient.get("/meds/ingredient/" + ingredientId);
  },
  medsForMoa(moaId) {
    return apiClient.get("/meds/moa/" + moaId);
  },
  medNameSearchWithLimit(term) {
    return apiClient.get("/meds/search/" + term + "?limit=20");
  },
  medTypeSearch(type) {
    return apiClient.get("/meds/category/" + type);
  },
  oralMeds() {
    return apiClient.get("/meds/orals");
  },
  oralMedDetails(idORName) {
    return apiClient.get("/meds/orals/details/" + idORName);
  },
  oralNdcVariations(id) {
    return apiClient.get("/meds/orals/ndcs/" + id);
  },
  categoryIdSearch(categoryId) {
    return apiClient.get("/meds/categoryById/" + categoryId);
  },
  medDetails(idORName) {
    return apiClient.get("/meds/details/" + encodeURIComponent(idORName));
  },
  medCompany(labeler_ndc) {
    return apiClient.get("/meds/companies/ndc/" + labeler_ndc);
  },

  labelDataForNdc(ndc) {
    return apiClient.get("/meds/labeldata/" + ndc);
  },
  listingDataForNdc(ndc) {
    return apiClient.get("/meds/listingData/" + ndc);
  },

  // --------------------icd codes and billing code api calls----------------------------
  codeDetails(code) {
    return apiClient.get("/icd/code/" + code);
  },
  // aliasesForCode(code) {
  //   return apiClient.get("/icd/aliasesForCode/" + code);
  // },
  chapters() {
    return apiClient.get("/icd/chapters");
  },

  // icdSearch(term) {
  //   return apiClient.get("/icd/search/" + term);
  // },
  namesForCodeList(list) {
    // where list is a comma-separated list (eg "H00.01, H00.02, . . .")
    return apiClient.get("/icd/namesForCodeList?codes=" + list);
  },
  // icdSearchAll(term) {
  //   return apiClient.get("/icd/deepSearch/" + term);
  // },
  addAlias(code, name, alias, user) {
    let paramString = `code=${code}&name=${name}&alias=${alias}&user=${user}`;
    return apiClient.get("/icd/addAlias?" + paramString);
  },
  setFavorite(code, name, user) {
    let paramString = `code=${code}&name=${name}&user=${user}`;
    return apiClient.get("/icd/setFavorite?" + paramString);
  },
  removeFavorite(code, user) {
    let paramString = `code=${code}&user=${user}`;
    return apiClient.get("/icd/removeFavorite?" + paramString);
  },
  // billingCodes() {
  //   return apiClient.get("/billing/all");
  // },
  // billingMenu() {
  //   return apiClient.get("/billing/menuOptions");
  // },

  // ------------ ocular conditions (disease) API calls ----------
  conditionsContents() {
    return apiClient.get("/condition/contents");
  },
  conditionsList() {
    return apiClient.get("/condition/list");
  },
  conditionDetails(slugOrId, admin = false) {
    let path = "/condition/details/" + slugOrId;
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  conditionCategories() {
    return apiClient.get("/condition/categories");
  },
  // conditionsForCategory(slug) {
  //   return apiClient.get("/condition/category/" + slug);
  // },

  // ------------ rgp stuff ---------------
  // rgpLensOptions(admin = false) {
  //   let path = "/rgps/options";
  //   if (admin) path += "?admin=1";
  //   return apiClient.get(path);
  // },
  rgpLensCompanies(admin = false) {
    let path = "/rgps/lens_companies";
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  rgpCompanies(admin = false) {
    let path = "/rgps/companies";
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  rgpMaterials(admin = false, id = null) {
    let path = "/rgps/materials?";

    if (admin) path += "admin=1";
    if (id && admin) path += "&";
    if (id) path += "id=" + id;
    // console.log(path);
    // if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  rgpCompanyDetails(id, admin = false) {
    let path = "/rgps/company/" + id;
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  rgpLensDetails(id, admin = false) {
    let path = "/rgps/lens/" + id;
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  rgpLenses(admin = false) {
    let path = "/rgps/list";
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },

  // ----- ads --------------------------------------------------------
  getAds(paramString = null) {
    let url = "/ads/getAds";
    if (paramString) url = url + `?${paramString}`;
    return apiClient.get(url);
  },
  adClick(id) {
    return apiClient.get("/ads/adClick/" + id);
  },

  // ----------------------------------------------------------------

  // categoriesForSection(section) {
  //   return apiClient.get("/articles/categoriesForSection/" + section);
  // },
  // categoriesForSection(section) {
  //   return apiClient.get("/articles/articlesForSection/" + section);
  // },
  // firstSectionArticle(section) {
  //   return apiClient.get("/articles/firstSectionArticle/" + section);
  // },
  article(slug, admin = false) {
    let path = "/articles/" + slug;
    if (admin) path += "?admin=1";
    return apiClient.get(path);
  },
  // articleForSlug(slug) {
  //   return apiClient.get("/articles/" + slug);
  // },
  // articleForCategory(id) {
  //   return apiClient.get("/articles/category/" + id);
  // },

  articleList(paramString = null) {
    let path = "/articles/articleList";
    if (paramString) path += "?" + paramString;
    return apiClient.get(path);
  },

  // --------- references ---------
  references(paramString = null) {
    let path = "/references/tables";
    if (paramString) path += "?" + paramString;
    return apiClient.get(path);
  },
  ed_images(paramString = null) {
    let path = "/references/ed_images";
    if (paramString) path += "?" + paramString;
    return apiClient.get(path);
  },
  allProtocols(paramString = null) {
    let path = "/billing-protocols/all";
    if (paramString) path += "?" + paramString;
    return apiClient.get(path);
  },

  // ----------------------------------------------------------------
  // amember apis

  // loginWithUserAndPass(user, pass) {
  //   let paramString = `_key=${AMEMBER_KEY}`;
  //   paramString += `&login=${user}`;
  //   paramString += `&pass=${pass}`;
  //   return amemberApi.get("/check-access/by-login-pass?" + paramString);
  // },
  loginWithUserAndPass(user, pass) {
    let paramString = `&login=${encodeURIComponent(user)}`;
    paramString += `&pass=${encodeURIComponent(pass)}`;
    return apiClient.get("userLogin?" + paramString);
  },
  loginWithUser(user) {
    let paramString = `login=${encodeURIComponent(user)}`;
    return apiClient.get("userAccess?" + paramString);
  },
  userPreferences(params) {
    let paramString = new URLSearchParams(params).toString();
    return apiClient.get("/userPreferences?" + paramString);
  },
  setUserPreferences(data) {
    return apiClient.post("/setUserPreferences", data);
  },
  genericUpdateItem(path, data) {
    return apiClient.post(path, data);
  },

  // ---- admin stuff ---
  adminData(module, paramString) {
    return apiClient.get(`${module}/adminData?${paramString}`);
  },

  // loginWithUser(user) {
  //   let paramString = `_key=${AMEMBER_KEY}`;
  //   paramString += `&login=${user}`;
  //   return amemberApi.get("/check-access/by-login?" + paramString);
  // },

  // restorePassword(user) {
  //   //email or username
  //   let paramString = `_key=${AMEMBER_KEY}`;
  //   paramString += `&login=${user}`;
  //   return amemberApi.get("/check-access/send-pass?" + paramString);
  // },
  resetPassword(user) {
    let paramString = `login=${user}`;
    return apiClient.get("/resetPassword?" + paramString);
  },

  // -------------------------------------------------------------------

  markdownFile(file) {
    return markdownApi.get("?file=" + file);
  },
};
