export default {
  props: {},
  data: function () {
    return {
      currentModule: null,
      currentModuleTitle: null,
      currentModuleObj: null,
      moduleOptions: {
        "/documentation": {
          module: "documentation",
          browseTitle: "Browse Documentation",
          title: "Documentation",
        },
        "/blog": {
          module: "blog",
          browseTitle: "Browse Blog Posts",
          title: "Blog",
        },
        "/news": {
          module: "news",
          browseTitle: "Browse EyeDock News",
          title: "News",
        },
        "/meds": {
          module: "meds",
          browseTitle: "Browse Meds Categories",
          title: "Meds",
        },
        "/icd10": {
          module: "codes",
          browseTitle: "Browse ICD Categories",
          title: "Codes",
        },
        "/billing-codes": {
          module: "billingCodes",
          browseTitle: "Browse Billing Codes (E/M, CPT)",
          title: "Billing Codes",
        },
        "/coding-clinical-examples": {
          module: "coding-examples",
          browseTitle: "Clinical Examples for Coding & Billing",
          title: "Clinical Coding Examples",
        },
        "/coding": {
          module: "coding-home",
          browseTitle: "Diagnosis and Billing Codes",
          title: "Coding Home",
        },

        "/coding-widget": {
          module: "codingWidget",
          browseTitle: "Get help with billing codes",
          title: "Coding Widget",
        },
        "/cl-products": {
          module: "cl-products",
          browseTitle: "Contact Lens Solutions and Cleaners",
          title: "Contact Lens Products",
        },
        "/calcs": {
          module: "calcs",
          browseTitle: "Browse Calculators",
          title: "Calcs",
        },
        "/cls": {
          module: "cls",
          browseTitle: "Browse Soft CLs",
          title: "CLs",
        },
        "/rgps": {
          module: "rgps",
          browseTitle: "Browse GP & Specialty Lenses",
          title: "GPs",
        },
        "/hybrids": {
          module: "hybrids",
          browseTitle: "Browse GP & Specialty Lenses",
          title: "Hybrids",
        },
        // "/cl_prices": {
        //   module: "cl_prices",
        //   browseTitle: "CL price calculator",
        //   title: "CL Prices",
        // },

        "/ocular-conditions": {
          module: "disease",
          browseTitle: "Ocular Conditions Categories",
          title: "Conditions",
        },
        "/clinical-references": {
          module: "clinical-references",
          browseTitle: "Browse Clinical References",
          title: "Clinical References",
        },
        "/billing-protocols": {
          module: "billing-protocols",
          browseTitle: "Billing Protocols",
          title: "Billing Protocols",
        },
        "/educational-images": {
          module: "educational-images",
          browseTitle: "Filter Educational Images",
          title: "Educational Images",
        },
        "/search-all": {
          module: "search-all",
          browseTitle: "Search Everything",
          title: "Search Everything",
        },
        "/boss": {
          module: "admin",
          browseTitle: "Admin",
          title: "Admin Home",
        },
        "/": {
          module: "home",
          browseTitle: "Browse News & Blog Posts",
          title: "Home",
        },
      },
    };
  },
  methods: {
    setCurrentModule(route) {
      // console.log(route);
      let module = null;
      if (route) {
        // console.log("route", route);
        let moduleKeys = Object.keys(this.moduleOptions);
        for (const key of moduleKeys) {
          let startsWith = route.startsWith(key);
          if (key == "/") startsWith = route == key;
          if (startsWith) {
            let moduleObj = this.moduleOptions[key];
            this.currentModuleObj = moduleObj;
            module = moduleObj.module;
            this.currentModuleTitle = moduleObj.title;
            this.$emit("browseTitle", moduleObj.browseTitle);
            break;
          }
        }
      }
      this.currentModule = module;
    },
  },
  computed: {
    hideNav: function () {
      return this.currentModuleObj?.hideNav;
    },
  },
  watch: {
    $route(newRouteValue) {
      this.setCurrentModule(newRouteValue.path);
    },
  },
  mounted: function () {
    this.setCurrentModule(this.$route.path);
  },
};
